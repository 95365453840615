import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-staff-review',
  templateUrl: './staff-review.component.html',
  styleUrls: ['./staff-review.component.css']
})
export class StaffReviewComponent implements OnInit {
  current_purchase_month_dates:any = [];

  dateNow:any = new Date();
  datePicker:any = new Date();
  current_month_events:any = [];
  card:any;
  member:any;

  loaded:any = false;
  loading_data:any = false;

  constructor(private router:Router, private http:HttpClient, private route:ActivatedRoute) {
    this.card = this.route.snapshot.params['card'];
    let current = new Date();
    this.formulate(current);
  }

  ngOnInit(): void {
    this.http.get("api/service.php?action=get_staff_member&card="+ this.card).subscribe((data) => {
      this.member = data['member'];
      this.loaded = true;
    });
  }

  formulate(dt) {
    this.loading_data = true;
    this.current_purchase_month_dates = [];

    var firstDay = new Date(dt.getFullYear(), dt.getMonth(), 1);
    var lastDay = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
    let predate_num = 0;
    // GENERATE PREDATE
  

    for(let i = 0; i < lastDay.getDate(); i++) {
        let day_id = i + 1;
        let formal_date = dt.getFullYear() + "-" + this.returnPadZero(dt.getMonth() + 1, 2) + "-" + this.returnPadZero(day_id, 2);
        this.current_purchase_month_dates.push({ "date":day_id, "month":dt.getMonth(), "year":dt.getFullYear(), "segments":[], 'formal_date':formal_date });
        this.http.get("api/service.php?action=get_personal_events_for_month&date="+ formal_date +"&code=" + this.card).subscribe((data) => {
          this.current_purchase_month_dates[i].segments = data['segments'];
          for(let si = 0; si < this.current_purchase_month_dates[i].segments.length; si++) {
            this.current_purchase_month_dates[i].segments[si].width = ((parseInt(this.current_purchase_month_dates[i].segments[si].end) - parseInt(this.current_purchase_month_dates[i].segments[si].start)) / 2359) * 100;
            this.current_purchase_month_dates[i].segments[si].start_offset = (parseInt(this.current_purchase_month_dates[i].segments[si].start) / 2359) * 100;
          }
          if(i == lastDay.getDate() - 1) { this.loading_data = false; }
        });
    }

  }

  nextMonth() {
    this.datePicker.setMonth(this.datePicker.getMonth() + 1);
    this.formulate(this.datePicker);
  }

  prevMonth() {
    this.datePicker.setMonth(this.datePicker.getMonth() - 1);
    this.formulate(this.datePicker);
  }

  selectDate(dt) {
    if(dt.active)  {
      let month = dt.month + 1;
      this.router.navigateByUrl('/calendar-review/' + this.datePicker.getFullYear() + "-" + this.returnPadZero(month, 2) + "-" + this.returnPadZero(dt.date, 2));
    }
  }

  returnDateFormated(dt){
    return this.returnPadZero(dt.getDate(), 2) + "-" + this.returnPadZero(dt.getMonth()+ 1, 2) + "-" + dt.getFullYear();
  }

  returnYear(dt) { return dt.getFullYear(); }
  returnMonth(dt) { return dt.getMonth() + 1; }
  returnMonthName(month) {
    if(month == 1) { return "Януари"; }
    else if(month == 2) { return "Февруари"; }
    else if(month == 3) { return "Март"; }
    else if(month == 4) { return "Април"; }
    else if(month == 5) { return "Май"; }
    else if(month == 6) { return "Юни"; }
    else if(month == 7) { return "Юли"; }
    else if(month == 8) { return "Август"; }
    else if(month == 9) { return "Септември"; }
    else if(month == 10) { return "Октомври"; }
    else if(month == 11) { return "Ноември"; }
    else if(month == 12) { return "Декември"; }
    else { return ""; }
  }
  returnDate(dt) { return dt.getDate();  }

  returnPadZero(number, length) {
    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }
    return my_string;
  }

  returnDayHasEvent(dt) {
    let day_form = this.returnPadZero(dt.date, 2) + '-' + this.returnPadZero(dt.month + 1, 2) + '-' + dt.year;
    return this.current_month_events.filter(day => day == day_form).length;
  }

}
