<div class="content-title">Контролери</div>
<table class="table-wrapper" cellpadding="0" cellspacing="0">
  <thead>
    <tr>
      <th>Сериен №</th>
      <th>Име</th>
      <th>IP</th>
      <th>Състояние</th>
      <th>Действия</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let controller of controllers">
      <td>{{ controller.serial }}</td>
      <td>{{ controller.name }}</td>
      <td>{{ controller.ip }}</td>
      <td>
        <span class="badge positive" *ngIf="controller.active">Свързан</span>
        <span class="badge inactive" *ngIf="!controller.active">Липсва връзка</span>
        <!-- <span class="badge inactive" *ngIf="member.state == '0'">Липсва връзка</span> -->
      </td>
      <td>-</td>
    </tr>
  </tbody>
</table>
<div class="pagination">
    <span>{{ 1 + ((currentPage - 1) * 10) }}-{{ 1 + ((currentPage) * 10) }} от {{total_results}} Контролери</span>
</div>