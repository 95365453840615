<div style="float:left;width:100%;height:100%;" *ngIf="system.returnLogged()">
  <div class="sidebar">
    <div class="logo"></div>
    <ul class="sidemenu">
      <li routerLink="/staff" routerLinkActive="active"><i class="icon staff"></i></li>
      <li routerLink="/controllers" routerLinkActive="active"><i class="icon device"></i></li>
      <li routerLink="/calendar" routerLinkActive="active"><i class="icon calendar"></i></li>
    </ul>
  </div>
  <div class="navbar">
  
  </div>
  <div class="content">
    <router-outlet></router-outlet> 
  </div>
  
</div>

<div style="float:left;width:100%;height:100%;background-image: linear-gradient(#1f3242, #000);" *ngIf="!system.returnLogged()">
  <div class="login-form">
    <div class="input-row">
      <span class="label">Потребителско име</span>
      <input type="text" [(ngModel)]="loginForm.username" (keyup.enter)="system.Login(loginForm.username, loginForm.password, this)" />
    </div>
    <div class="input-row">
      <span class="label">Парола</span>
      <input type="password" [(ngModel)]="loginForm.password" (keyup.enter)="system.Login(loginForm.username, loginForm.password, this)" />
    </div>
    <div class="btn" (click)="system.Login(loginForm.username, loginForm.password, this)">Вход</div>
  </div>
</div>
