import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CalendarReviewComponent } from './calendar-review/calendar-review.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ControllersComponent } from './controllers/controllers.component';
import { StaffReviewComponent } from './staff-review/staff-review.component';
import { StaffComponent } from './staff/staff.component';

const routes: Routes = [
  { path: 'staff', component: StaffComponent },
  { path: 'controllers', component: ControllersComponent },
  { path: 'calendar', component: CalendarComponent },
  { path: 'calendar-review/:date', component:CalendarReviewComponent },
  { path: 'staff-review/:card', component:StaffReviewComponent },
  { path: '',   redirectTo: '/staff', pathMatch: 'full' }, // redirect to 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
