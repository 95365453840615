<div class="content-title">Календар</div>
<div class="sub-title">
    <i class="action-left icon arrow-left" (click)="prevMonth()"></i>
    <span>{{ returnMonthName(returnMonth(datePicker)) }} {{ returnYear(datePicker) }}</span>
    <i class="action-right icon arrow-right" (click)="nextMonth()"></i>
</div>
<div class="date-grid">
    <div class="grid-hdr">
        <span>Понеделник</span>
        <span>Вторник</span>
        <span>Сряда</span>
        <span>Четвъртък</span>
        <span>Петък</span>
        <span>Събота</span>
        <span>Неделя</span>
    </div>
    <div class="grid-body">
        <span *ngFor="let dt of current_purchase_month_dates" [ngClass]="{ 'disabled':!dt.active, 'selected':dt.date == returnDate(datePicker) && dt.month == returnMonth(dateNow) - 1, 'current':dt.date == returnDate(dateNow) && dt.month == returnMonth(dateNow) - 1 }" (click)="selectDate(dt)">
            <span>{{ dt.date }}</span>
            <span class="st" *ngIf="returnDayHasEvent(dt) > 0"></span>
        </span>
    </div>
</div>