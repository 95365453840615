import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {
  staff:any = [];
  currentPage:any = 1;
  total_results:any = 0;
  total_pages:any;
  refreshInterval:any;

  constructor(private http:HttpClient) { }

  ngOnInit(): void {
    this.getStaff();
  }

  goPrev() {
    if(this.currentPage > 1) {
      this.currentPage -= 1;
      this.getStaff();
    }
  }

  goNext() {
    if(this.currentPage < this.total_pages) {
      this.currentPage += 1;
      this.getStaff();
    }
  }

  getStaff() {
    this.http.get("api/service.php?action=get_staff&page=" + this.currentPage).subscribe((data) => {
      this.staff = data['staff']
      this.total_results = data['total_results'];
      this.total_pages = Math.ceil(this.total_results / 10);
    });
    clearInterval(this.refreshInterval);
    this.refreshInterval = setInterval(() => {
      this.updateStaff();
    },4000)
  }

  updateStaff() {
    this.http.get("api/service.php?action=get_staff&page=" + this.currentPage).subscribe((data) => {
      this.staff = data['staff']
      this.total_results = data['total_results'];
    });
  }

  viewPhoto(member) {
    this.http.get("api/service.php?action=get_photo&card=" + member.serial).subscribe((data) => {
      let link = data['link'].split("html/")[1];
      let final_link = "http://78.130.151.170:9090/" + link;
      window.location.href = final_link;
    });
  }

}
