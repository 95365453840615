import { HttpClient } from '@angular/common/http';
import { Route } from '@angular/compiler/src/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LocalSystemService { 
  logged:any = false;

  constructor(private http:HttpClient, private router:Router) {
    this.http.get("api/service.php?action=get_staff&page=1").subscribe((data) => {
      this.logged = data['logged'];
    });
  }

  Login(username, password, mdl) {
    this.http.get("api/service.php?action=login&username=" + username + "&password=" + password).subscribe((data) => {
      this.logged = data['logged'];
      if(this.logged) {
        mdl.loginForm = { username:"", password:"" };
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
        });
      }
    });
  }

  returnLogged() { return this.logged; }
}
