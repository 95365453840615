import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  current_purchase_month_dates:any = [];

  dateNow:any = new Date();
  datePicker:any = new Date();
  current_month_events:any = [];

  constructor(private router:Router, private http:HttpClient) {
    let current = new Date();
    this.formulate(current);
  }

  ngOnInit(): void {
  }

  formulate(dt) {
    this.current_purchase_month_dates = [];

    var firstDay = new Date(dt.getFullYear(), dt.getMonth(), 1);
    var lastDay = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
    let predate_num = 0;
    // GENERATE PREDATE
  
    for(let i = 0; i < 42 - lastDay.getDate(); i++) {
        let day_idx = i;
        if(day_idx < firstDay.getDay() - 1) {
            let negative_day = day_idx * -1;
            let date_reformed = new Date(dt.getFullYear(), dt.getMonth(), negative_day);
            this.current_purchase_month_dates.unshift({ "date": date_reformed.getDate(), "month":date_reformed.getMonth(), "year":date_reformed.getFullYear(), "active":false})
            predate_num += 1;
        }
    }
    for(let i = 0; i < lastDay.getDate(); i++) {
        let day_id = i + 1;
        this.current_purchase_month_dates.push({ "date":day_id, "month":dt.getMonth(), "year":dt.getFullYear(), "active":true });
    }
    for(let i = 0; i < 42 - lastDay.getDate() - predate_num; i++) {
        let date_reformed = new Date(dt.getFullYear(), dt.getMonth() + 2, i + 1);
        this.current_purchase_month_dates.push({ "date":date_reformed.getDate(), "month":date_reformed.getMonth(), "year":date_reformed.getFullYear(), "active":false });
    }

    let start_date = + firstDay.getFullYear() + '-' + this.returnPadZero(firstDay.getMonth() + 1, 2) + '-' +  this.returnPadZero(firstDay.getDate(), 2);
    let end_date = + lastDay.getFullYear() + '-' + this.returnPadZero(lastDay.getMonth() + 1, 2) + '-' + this.returnPadZero(lastDay.getDate(), 2);
    this.http.get("api/service.php?action=get_month_events&date_start=" + start_date + "&date_end=" + end_date).subscribe((data) => {
      this.current_month_events = data['days'];
    });
  }

  nextMonth() {
    this.datePicker.setMonth(this.datePicker.getMonth() + 1);
    this.formulate(this.datePicker);
  }

  prevMonth() {
    this.datePicker.setMonth(this.datePicker.getMonth() - 1);
    this.formulate(this.datePicker);
  }

  selectDate(dt) {
    if(dt.active)  {
      let month = dt.month + 1;
      this.router.navigateByUrl('/calendar-review/' + this.datePicker.getFullYear() + "-" + this.returnPadZero(month, 2) + "-" + this.returnPadZero(dt.date, 2));
    }
  }

  returnDateFormated(dt){
    return this.returnPadZero(dt.getDate(), 2) + "-" + this.returnPadZero(dt.getMonth()+ 1, 2) + "-" + dt.getFullYear();
  }

  returnYear(dt) { return dt.getFullYear(); }
  returnMonth(dt) { return dt.getMonth() + 1; }
  returnMonthName(month) {
    if(month == 1) { return "Януари"; }
    else if(month == 2) { return "Февруари"; }
    else if(month == 3) { return "Март"; }
    else if(month == 4) { return "Април"; }
    else if(month == 5) { return "Май"; }
    else if(month == 6) { return "Юни"; }
    else if(month == 7) { return "Юли"; }
    else if(month == 8) { return "Август"; }
    else if(month == 9) { return "Септември"; }
    else if(month == 10) { return "Октомври"; }
    else if(month == 11) { return "Ноември"; }
    else if(month == 12) { return "Декември"; }
    else { return ""; }
  }
  returnDate(dt) { return dt.getDate();  }

  returnPadZero(number, length) {
    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }
    return my_string;
  }

  returnDayHasEvent(dt) {
    let day_form = this.returnPadZero(dt.date, 2) + '-' + this.returnPadZero(dt.month + 1, 2) + '-' + dt.year;
    return this.current_month_events.filter(day => day == day_form).length;
  }
}
