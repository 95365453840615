import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-controllers',
  templateUrl: './controllers.component.html',
  styleUrls: ['./controllers.component.css']
})
export class ControllersComponent implements OnInit {
  controllers:any = [];
  currentPage:any = 1;
  total_results:any = 0;
  refreshInterval:any;


  constructor(private http:HttpClient) {
    this.getControllers();
  }

  getControllers() {
    this.http.get("api/service.php?action=get_controllers&page=" + this.currentPage).subscribe((data) => {
      this.controllers = data['controllers'];
      this.total_results = data['total_results'];
    });
    clearInterval(this.refreshInterval);
    this.refreshInterval = setInterval(() => {
      this.getControllers();
    },4000)
  }

  ngOnInit(): void {
  }

}
