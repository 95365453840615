import { Component } from '@angular/core';
import { LocalSystemService } from './local-system.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'accessControlCMS';
  loginForm:any = { username:"", password:"" };

  constructor(public system:LocalSystemService){}
}
