import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-calendar-review',
  templateUrl: './calendar-review.component.html',
  styleUrls: ['./calendar-review.component.css']
})
export class CalendarReviewComponent implements OnInit {
  staff:any = [];
  date_now:any = "";
  date_obj:any;

  constructor(private http:HttpClient, private route:ActivatedRoute) {
    this.date_now = this.route.snapshot.params['date'];
    this.date_obj = new Date(this.date_now);
    this.getSchedules();
  }

  ngOnInit(): void {
  }

  getSchedules() {
    this.http.get("api/service.php?action=get_date_schedule&date=" + this.date_now).subscribe((data) => {
      this.staff = data['staff']
      for(let i = 0; i < this.staff.length; i++) {
        for(let si = 0; si < this.staff[i].segments.length; si++) {
          this.staff[i].segments[si].width = ((parseInt(this.staff[i].segments[si].end) - parseInt(this.staff[i].segments[si].start)) / 2359) * 100;
          this.staff[i].segments[si].start_offset = (parseInt(this.staff[i].segments[si].start) / 2359) * 100;
        }
      }
    });
  }

  returnDateFormated(dt){
    return this.returnPadZero(dt.getDate(), 2) + "." + this.returnPadZero(dt.getMonth()+ 1, 2) + "." + dt.getFullYear();
  }

  returnPadZero(number, length) {
    var my_string = '' + number;
    while (my_string.length < length) {
        my_string = '0' + my_string;
    }
    return my_string;
  }

}
