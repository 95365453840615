<div class="modal-layer">
    <div class="backdrop"></div>
    <div class="mdl"></div>
</div>


<div class="content-title">
    <i class="action-left icon arrow-back" routerLink="/calendar"></i>
    Работно време - {{ returnDateFormated(date_obj) }}
</div>
<div class="timetable-hdr">
    <div class="primary">Персонал</div>
    <div class="hours">
        <span>00:00</span>
        <span>03:00</span>
        <span>06:00</span>
        <span>09:00</span>
        <span>12:00</span>
        <span>15:00</span>
        <span>18:00</span>
        <span>21:00</span>
    </div>
</div>
<div class="timetable-body">
    <div class='timeline-row' *ngFor="let member of staff">
        <span class="name">{{ member.user }}</span>
        <div class="line">
            <div class="fill" *ngFor="let segment of member.segments" [ngClass]="{ 'hidden':segment.start_hour == undefined || segment.end_hour == undefined || segment.end_hour == '' }" [ngStyle]="{ 'width':segment.width + '%', 'left':segment.start_offset + '%' }">
                <span *ngIf="segment.start_hour != '' && segment.end_hour != ''">{{ segment.start_hour }} - {{ segment.end_hour }} </span>
            </div>
        </div>
    </div>
</div>