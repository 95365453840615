import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StaffComponent } from './staff/staff.component';
import { ControllersComponent } from './controllers/controllers.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarReviewComponent } from './calendar-review/calendar-review.component';
import { FormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { StaffReviewComponent } from './staff-review/staff-review.component';

@NgModule({
  declarations: [
    AppComponent,
    StaffComponent,
    ControllersComponent,
    CalendarComponent,
    CalendarReviewComponent,
    StaffReviewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [Location, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
