<div class="content-title">Персонал</div>
<table class="table-wrapper" cellpadding="0" cellspacing="0">
  <thead>
    <tr>
      <th>Име</th>
      <th>Карта №</th>
      <th>Състояние</th>
      <th>Последен вход</th>
      <th>Последен изход</th>
      <th>Действия</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let member of staff">
      <td>{{ member.user }}</td>
      <td>{{ member.serial }}</td>
      <td>
        <span class="badge positive" *ngIf="member.state == '1'">На работа</span>
        <span class="badge inactive" *ngIf="member.state == '0'">Извън работа</span>
      </td>
      <td>{{ member.last_entry }}</td>
      <td>{{ member.last_exit }}</td>
      <td>
        <div class="action" (click)="viewPhoto(member)"><i class="icon view-photo"></i></div>
        <div class="action" [routerLink]="['/staff-review', member.serial]"><i class="icon calendar-staff"></i></div>
      </td>
    </tr>
  </tbody>
</table>

<div class="pagination">
    <span>{{ 1 + ((currentPage - 1) * 10) }}-{{ 1 + ((currentPage) * 10) }} от {{total_results}} Персонал </span>
    <div class="page-control">
      <div class="page-left" [ngClass]="{ 'selectable':currentPage > 1 }" (click)="goPrev()"><</div>
      <div class="page-right" [ngClass]="{ 'selectable':currentPage < total_pages }" (click)="goNext()">></div>
    </div>
  </div>